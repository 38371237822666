<template>
  <!-- <div class="select" style="margin-left:12px;magin-bottom:10px">
    <select v-model="$i18n.locale" @change="changeLang">
      <option value="en">English</option> 
      <option value="fr">France</option>
      <option value="de">Deutschland</option>
    </select>
  </div> -->
  <div class="msg">
    <div class="card p-2 overflow-hidden">
      <div class="text-white p-2  rounded-t-2xl" style="background: #888;padding-left:20px">{{ $t("letter_product information") }}</div>
      <div class="banner relative">
        <!-- <div class="logo absolute"></div> -->
		<!--
        <span class="text w-5/6  text-gray-600 logo-font">{{ $t("letter_title") }}</span>
        <span class="text w-5/6  text-gray-600  logo-font">{{ $t("letter_title1") }}</span>
        <span class="text w-5/6  text-gray-600  logo-font">{{ $t("letter_title2") }}</span>
		-->
      </div>
    </div>
    <div class="card p-2 overflow-hidden">
      <div class="text-white p-2  rounded-t-2xl" style="background: #888;padding-left:20px">{{ $t("letter_submit_voucher_30") }}</div>
      <div class="p-2">
        <el-form ref="ruleFormRef" label-position="top" label-width="100px" :model="form" :rules="rules" class="w-full" label-suffix="">
          <el-form-item prop="email">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_Email") }}</span>
            </template>
            <el-input v-model="form.email" />
          </el-form-item>

          <el-form-item prop="OrderNumber" v-show="false">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_order") }}</span>
              <div>
                <el-link type="primary" @click="preview1">{{ $t("letter_Clickhere for the how-to guide") }}</el-link>
              </div>
            </template>
            <el-input v-model="form.OrderNumber" />
          </el-form-item>

          <el-form-item prop="select the option">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_Please select the option you want") }}</span>
              <div style="margin:5px;diplay:block"> {{ $t("letter_ATTENTION") }}: {{ $t("letter_For your account security, please don't attach pictures of letter when you leave a product review.") }}</div>
            </template>

            <el-radio-group v-model="form.option">
              <div class="flex flex-wrap">
                <div v-for="item in options" :key="item.value" class="mr-2 mb-2">
                  <div>
                    <el-image style="width: 260px;" :src="item.url" fit="contain" />
                  </div>
                  <el-radio :label="item.value" size="large" border>
                    {{ item.label }}
                  </el-radio>
                </div>
              </div>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="refund method">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_Please select a refund method") }}</span>
            </template>

            <el-radio-group v-model="form.refund_option">
              <div class="flex flex-wrap">
                <div v-for="item in refund_options" :key="item.value" class="mr-2 mb-2">
                  <el-radio :label="item.value" size="large" border>
                    ${{ refund_options_arr[form.option - 1] }}{{ item.label }}
                  </el-radio>
                </div>
              </div>
            </el-radio-group>
            <div v-show="form.refund_option==1" style="display:block !important;margin-left:5px;color:gray">{{ $t("letter_Use the gift card's claim code when checking out") }}</div>
          </el-form-item>
          <!-- <el-form-item v-show="form.refund_option==1">
            <template v-slot:label>
              <span style="margin-left:5px;color:gray">Use the gift card's claim code when checking out</span>
            </template>
          </el-form-item> -->
          <el-form-item prop="paypal_id" v-show="form.refund_option == 2">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_Your PayPal Account") }}</span>
            </template>

            <el-input v-model="form.paypal_id" />
          </el-form-item>

          <el-form-item prop="image">
            <template v-slot:label>
              <span class="red">[{{ $t("letter_Required") }}]</span>
              <span>{{ $t("letter_Give us 5 star ratings and take a screenshot and upload") }}</span>
              <div style="margin:5px"> {{ $t("letter_To ensure fast cashing out of your prize, please submit the correct screenshot and check your email within 24 hours.") }}</div>
              <div>
                <div><el-link href="https://www.amazon.ca/gp/css/order-history?ref_=nav_orders_first" type="primary">{{ $t("letter_Click here to write a five-star review for our product") }} </el-link>
                </div>
                <!-- <div><el-link @click="preview" type="primary">{{ $t("letter_Click here to see sample images") }}
                  </el-link>
                </div> -->
              </div>
            </template>
            <div class="flex flex-wrap">
              <div class="w-24 h-24 relative border border-gray-400 border-dashed rounded-md mr-2 mb-2" v-for="item, index in form.image" :key="item">
                <div class="absolute top-0 right-0 z-10 bg-gray-400 w-5 h-5 flex justify-center items-center" @click="del(index)">
                  <el-icon>
                    <Close color="#fff" />
                  </el-icon>
                </div>
                <el-image class="w-24 h-24 mr-2 mb-2" :src="item" fit="contain"></el-image>
              </div>
              <el-upload class="w-24 h-24 border border-gray-400 border-dashed rounded-md  flex justify-center items-center" accept=".png,.jpg" :show-file-list="false" :on-success="handleAvatarSuccess" :http-request="change" :on-remove="handleRemove">
                <el-icon size="22px">
                  <Plus />
                </el-icon>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item style="display:flex;justify-content:center">
            <div class="flex-1 flex justify-center">
              <el-button type="primary" @click="submitForm()">
                {{ $t("letter_summit") }}
              </el-button>

            </div>
            <!-- <el-button @click="resetForm(ruleFormRef)">Reset</el-button> -->
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>
<script setup>
import { letter } from "@/api/index.js";
import { put } from '@/api/ali'
import { reactive, ref, h } from 'vue';
import { Plus, Close } from '@element-plus/icons-vue'
import { ElMessageBox, ElImage } from 'element-plus'
let form = reactive({
  email: '',
  OrderNumber: '',
  option: 1,
  refund_option: 1,
  paypal_id: "",
  image: [],
  path: "prize"
})

let options = reactive([
  { label: 'Only 5 star ratings to get $10', url: '/option-1.jpeg', value: 1 },
  { label: '5 star ratings and review to get $20', url: '/option-2.jpeg', value: 2 },
//  { label: '5 star ratings,review and tiktok to get $30', url: '/option-3.jpeg', value: 3 },
])

let refund_options = reactive([
  { label: 'Amazon Gift Card', value: 1 },
  { label: 'Paypal Transfer', value: 2 },
])

let refund_options_arr = ["10", "20", "30"]

const ruleFormRef = ref()
let rules = reactive({
  email: [
    { required: true, message: 'Please fill in your email address', trigger: 'blur' },
  ],
  //   OrderNumber: [
  //     { required: true, message: 'Please fill in your amazon order number', trigger: 'blur' },
  //   ],
  option: [
    { required: true, message: 'Please select the option you want', trigger: 'blur' },
  ],
  //   paypal_id: [
  //     { required: true, message: 'Please fill in your paypal account', trigger: 'blur' },
  //   ],
  image: [
    { required: true, message: 'Please Write a five-star product review and take a screenshot and upload', trigger: 'blur' },
  ]
})

const submitForm = async () => {
  if (!ruleFormRef.value) return
  let a = await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      return true
    } else {
      console.log('error submit!', fields)
      return false
    }
  })
  if (a) {
    console.log('submit!')
    let res = await letter(form);
    console.log(res);
    if (res.code === 200) {
      return ok()
    } else {
      return alert("Please check the network!");
    }
  }
}

const handleRemove = async (e) => {
  alert(e)
}
const change = async (e) => {
  let item = e.file // 文件信息
  if (!item) {
    return false
  }
  //   let item = document.getElementById("upload").files[0];
  console.log(item)
  var fileName = item.name  // 当前本地上传的这张图片的名称(没有时间日期)
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  month = (month < 10 ? '0' + month : month)
  var mydate = date.getDate()
  mydate = (mydate < 10 ? '0' + mydate : mydate)
  var baseurl = 'img/' + year + '/' + year + month + '/' + year + month + mydate + '/'
  // 这里是把时间+图片名称拼接起来形成一个新的图片上传至oss，目的是区别于本地图片的名称，避免名称相同会误删，同时便于查看oss上最新上传图片的时间点
  var filePath = baseurl + new Date().getTime() + '-' + fileName
  console.log(filePath)
  var file = item // 当前本地上传的这张图片
  await put(filePath, file).then(result => {  // 调oss api 上传图片
    console.log(result)
    // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
    //         this.fileList.push(result.name)  
    form.image.push(result.url);
  })

}

// function preview (e, src = '/00.jpg') {
//   e.preventDefault()
//   ElMessageBox.alert(h(ElImage, {
//     src,
//     fit: "contain",
//     class: 'w-full'
//   }), '', {
//     confirmButtonText: 'OK',
//     callback: () => {
//       // ElMessage({
//       //   type: 'info',
//       //   message: `action: ${action}`,
//       // })
//     },
//   })
// }

function ok (src = '/ok.png') {
  //   e.preventDefault()
  ElMessageBox.alert(h(ElImage, {
    src,
    fit: "contain",
    class: 'w-full1',

  }), '', {
    confirmButtonText: 'OK',
    callback: () => {
      // ElMessage({
      //   type: 'info',
      //   message: `action: ${action}`,
      // })
    },
  })
}
function preview1 (e, src = ['/11.jpg', '/22.jpg', '/33.png']) {
  e.preventDefault()
  ElMessageBox.alert(h(ElImage, {
    src: src[0],
    fit: "contain",
    class: 'w-full',
    previewSrcList: src
  }), '', {
    confirmButtonText: 'OK',
    callback: () => {
      // ElMessage({
      //   type: 'info',
      //   message: `action: ${action}`,
      // })
    },
  })
}

const del = index => {
  form.image.splice(index, 1)
}


</script>
<style>
.el-message-box {
  @media only screen and (min-width: 768px) {
    width: 20% !important; 
  }
  /* opacity: 0.7; */
  @media only screen and (max-width: 768px) {
    width: 80% !important; 
  }
}
</style>
<style lang="scss" scoped>
.msg {
  margin: 0;
  .banner {
    background: url(/prize_banner.jpg);
    background-size: 100% 100%;
    background-position: center center;
    //     width: 97.5vw;
    //     margin-left: 5px;
    height: 61vw;
    @media only screen and (min-width: 1200px) {
      height: 41vw;
    }
    .logo {
      background: url(/logo6.png);
      background-size: 100% 100%;
      width: 20vw;
      height: 15vw;
      z-index: 99;
      right: -10px;
      top: -10px;
      @media only screen and (min-width: 1200px) {
        width: 15vw;
        height: 13vw;
        z-index: 99;
        right: -17px;
        top: -24px;
      }
    }
    .logo-font {
      display: block;
      font-size: 0.65rem;
      padding-left: 1rem;
      line-height: 1rem;
      @media only screen and (min-width: 1200px) {
        margin: 0;
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 0;
      }
    }
  }

  .red {
    color: #f56c6c;
  }

  .card {
  }

  .select {
    //   position: absolute;
    //   right: 15px;
    //     float: right;
    //     position: fixed;
    margin-left: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
    //     top: 15px;
    z-index: 9999;
    color: #333;

    select {
      border-radius: 40px;
      padding-left: 20px;
      border: none;
      color: #666;
      line-height: 26px;
      height: 26px;
      //       left: 15px;
    }
  }
}
</style>